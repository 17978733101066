.subjects {
    background-color: var(--secondary);
  }
  
  .box-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
  
  .heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  .box {
    padding: 3rem 2rem;
    size: 40px; /* This property seems to be invalid */
    text-align: center;
    border-radius: 2%;
    border: .1rem solid var(--primary-color);
    background: var(--white);
    box-shadow: var(--box-shadow);
    cursor: pointer;
  }
  
  .box:hover {
    background: var(--primary-color);
  }
  
  .box img {
    height: 18rem;
    margin-bottom: .7rem;
  }
  
  .box h3 {
    font-size: 1.7rem;
    color: #444;
    padding: .5rem 0;
    text-transform: capitalize;
  }
  
  .box:hover h3 {
    color: #fff;
  }
  
  .box p {
    font-size: 1.5rem;
    line-height: 2;
    color: #777;
  }
  
  .box:hover p {
    color: #eee;
  }
  

  @media screen and (max-width: 800px) {
    .box-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  
  @media screen and (min-width: 801px) and (max-width: 1500px) {
    .box-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }