/* /* /* .container {
    display: flex;
    justify-content: space-between;
    margin: 50px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .formContainer,
  .receiptContainer {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .formContainer {
    margin-right: 20px;
  }
  
  .receiptContainer {
    margin-left: 20px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .formActions {
    display: flex;
    justify-content: space-between;
  }
  
  .errorMessage {
    color: red;
    margin-top: 5px;
  }
  
  .receiptActions {
    margin-top: 20px;
    text-align: center;
  }
  
  .receiptActions button {
    margin: 0 10px;
  }
   */
   .container {
    display: flex;
    justify-content: space-between;
    margin: 50px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .formContainer, .receiptContainer {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .formContainer {
    margin-right: 20px;
  }
  
  .receiptContainer {
    margin-left: 20px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .formActions {
    display: flex;
    justify-content: space-between;
  }
  
  .errorMessage {
    color: red;
    margin-top: 5px;
  }
  
  .receiptActions {
    margin-top: 20px;
    text-align: center;
  }
  
  .receiptActions button {
    margin: 0 10px;
  }
  
  /* Print styles 
  @media print {
    .container {
      display: block;
    }
    .formContainer {
      display: none;
    }
    .receiptContainer {
      margin: 0;
      padding: 0;
      border: none;
      background-color: #fff;
    }
  }
   
   .receipt-container {
    padding: 20px;
    border: 1px solid #ddd;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.5;
  }
  
  .page-container {
    text-align: right;
    margin: 20px 0;
  }
  
  .logo-container img {
    height: 18px;
    display: block;
    margin: 0 auto 20px;
  }
  
  .invoice-info-container {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
  }
  
  .invoice-info-container td {
    padding: 5px;
  }
  
  .client-name {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .line-items-container {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .line-items-container th,
  .line-items-container td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .heading-quantity,
  .heading-price,
  .heading-subtotal {
    text-align: right;
  }
  
  .right {
    text-align: right;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .has-bottom-border {
    border-bottom: 2px solid #000;
  }
  
  .large {
    font-size: 1.2em;
  }
  
  .total {
    font-weight: bold;
  }
  
  .footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-info span {
    margin: 0 10px;
  }
  
  .footer-thanks {
    margin-top: 10px;
  }
  
  .footer-thanks img {
    height: 20px;
    vertical-align: middle;
  }
   */
   body {
    font-size: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table tr td {
    padding: 0;
  }
  
  table tr td:last-child {
    text-align: right;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .right {
    text-align: right;
  }
  
  .large {
    font-size: 1.75em;
  }
  
  .total {
    font-weight: bold;
    color: #fb7578;
  }
  
  .logo-container {
    margin: 20px 0 70px 0;
  }
  
  .invoice-info-container {
    font-size: 0.875em;
  }
  
  .invoice-info-container td {
    padding: 4px 0;
  }
  
  .client-name {
    font-size: 1.5em;
    vertical-align: top;
  }
  
  .line-items-container {
    margin: 70px 0;
    font-size: 0.875em;
  }
  
  .line-items-container th {
    text-align: left;
    color: #999;
    border-bottom: 2px solid #ddd;
    padding: 10px 0 15px 0;
    font-size: 0.75em;
    text-transform: uppercase;
  }
  
  .line-items-container th:last-child {
    text-align: right;
  }
  
  .line-items-container td {
    padding: 15px 0;
  }
  
  .line-items-container tbody tr:first-child td {
    padding-top: 25px;
  }
  
  .line-items-container.has-bottom-border tbody tr:last-child td {
    padding-bottom: 25px;
    border-bottom: 2px solid #ddd;
  }
  
  .line-items-container.has-bottom-border {
    margin-bottom: 0;
  }
  
  .line-items-container th.heading-quantity {
    width: 50px;
  }
  
  .line-items-container th.heading-price {
    text-align: right;
    width: 100px;
  }
  
  .line-items-container th.heading-subtotal {
    width: 100px;
  }
  
  .payment-info {
    width: 38%;
    font-size: 0.75em;
    line-height: 1.5;
  }
  
  .footer {
    margin-top: 100px;
  }
  
  .footer-thanks {
    font-size: 1.125em;
  }
  
  .footer-thanks img {
    display: inline-block;
    position: relative;
    top: 1px;
    width: 16px;
    margin-right: 4px;
  }
  
  .footer-info {
    float: right;
    margin-top: 5px;
    font-size: 0.75em;
    color: #ccc;
  }
  
  .footer-info span {
    padding: 0 5px;
    color: black;
  }
  
  .footer-info span:last-child {
    padding-right: 0;
  }
  
  .page-container {
    display: none;
  }
  
  .container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.5;
  }
  
  .formContainer {
    margin-bottom: 20px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .formActions {
    display: flex;
    justify-content: space-between;
  }
  
  .errorMessage {
    color: red;
    font-size: 0.9em;
  }
  
  .receiptContainer {
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .receiptActions,
  .emailContainer {
    margin-top: 20px;
    text-align: center;
  }
  
  .emailContainer input {
    width: calc(100% - 110px);
    display: inline-block;
    padding: 8px;
    margin-right: 10px;
    box-sizing: border-box;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .formContainer {
    flex: 1;
    margin-right: 20px;
  }
  
  .invoice {
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 20px;
  }
  
  .invoice header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .invoice header .logo {
    margin-right: 10px;
  }
  
  .invoice header .companyName {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .invoice .addresses {
    display: flex;
    margin-bottom: 20px;
  }
  
  .invoice .addresses .from,
  .invoice .addresses .to {
    flex: 1;
  }
  
  .invoice table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice table th,
  .invoice table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .invoice .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .invoice .footer .footerInfo {
    font-size: 0.875em;
    color: #888;
  }
  
  .invoice .footer .footerThanks {
    display: flex;
    align-items: center;
    font-size: 1.125em;
  }
  
  .invoice .footer .footerThanks img {
    width: 20px;
    margin-right: 5px;
  }
  