/* footer.module.css */

.footer {
    background-color: #181d38 !important;
    text-align: center;
}

.box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.box-container .box img {
    background-color: var(--secondary);
    border-radius: 10px;
}

.box-container .box h3 {
    font-size: 2.2rem;
    color: white;
    padding: 1rem 0;
    text-decoration: underline 1px;
    text-underline-offset: 3px;
}

.box-container .box p {
    font-size: 18px;
    color: white;
    line-height: 0.5;
    padding: 1rem 0;
}

.box-container .box .quick-link {
    display: grid;
}

.box-container .box .quick-link a {
    color: white;
    font-size: 18px;
    padding-top: 0.5rem;
    text-align: center;
}

.footer .box-container .box a{
    color: white;
  }

  .footer .box-container .box a:hover{
    color: gray;
  }

.box-container .box .quick-link a:hover {
    color: gray;
}

.box-container .box .share {
    margin-top: 1rem;
}

.box-container .box .share a {
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    border-radius: 10%;
    font-size: 1.7rem;
    color: white;
    margin-right: .3rem;
    text-align: center;
}

.box-container .box .share a:hover {
    background: white;
    color: #181d38;
}

.box-container .box .link {
    font-size: 1.7rem;
    line-height: 2;
    color: white;
    display: block;
}

.box-container .box .link:hover {
    color: gray;
    text-decoration: underline;
}
.heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  .footer .credit{
    font-size: 2rem;
    margin-top: 3rem;
    padding-top: 3rem;
    text-align: center;
    color: white;
    border-top: .1rem solid white;
  }
  .footer .credit a{
    color: white;
    text-decoration: underline !important;
    text-underline-offset: 3px !important;
  }

  
  .footer .credit span{
    color: var(--primary-color);
  }
  

.adress {
    line-height: 1.5 !important;
}
@media (max-width:350px){
    .box-container .box{
        border-bottom: .1rem solid white;
    }
    .footer .credit{
        border-top: none !important;
    }

  
  }