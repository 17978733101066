* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  body {
    background-color: #f7f9fd;
  }
  .wrapper {
    width: 100%;
    font-size: 16px;
    padding: 1rem 5rem;
    /* max-width: 43.75em; */
    margin: 1em auto;
  }
  .container {
    width: 100%;
  }
  .sub-container {
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1em;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrapper h3 {
    color: #363d55;
    font-weight: 500;
    margin-bottom: 0.6em;
  }
  .container input {
    display: block;
    width: 100%;
    padding: 0.6em 0.3em;
    border: 1px solid #d0d0d0;
    border-radius: 0.3em;
    color: #414a67;
    outline: none;
    font-weight: 400;
    margin-bottom: 0.6em;
  }
  .container input:focus {
    border-color: #3c91e6;
  }
  .total-amount-container,
  .user-amount-container {
    width: 23.5rem;
    background-color: #ffffff;
    padding: 1.25em 0.9em;
    border-radius: 0.3em;
    box-shadow: 0 0.6em 1.2em rgba(28, 0, 80, 0.06);
  }
  .output-container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #3c91e6;
    color: #ffffff;
    border-radius: 0.3em;
    box-shadow: 0 0.6em 1.2em rgba(28, 0, 80, 0.06);
    margin: 2em 0;
    padding: 1.2em;
  }
  .output-container p {
    font-weight: 500;
    margin-bottom: 0.6em;
  }
  .output-container span {
    display: block;
    text-align: center;
    font-weight: 400;
    color: #e5e5e5;
  }
  .submit {
    font-size: 1em;
    margin-top: 0.8em;
    background-color: #3c91e6;
    border: none;
    outline: none;
    color: #ffffff;
    padding: 0.6em 1em;
    border-radius: 0.3em;
    cursor: pointer;
    width:fit-content;
  }
  .list {
    background-color: #ffffff;
    padding: 1.8em 1.2em;
    text-align: center;
    box-shadow: 0 0.6em 1.2em rgba(28, 0, 80, 0.06);
    border-radius: 0.6em;
    
  }
  .list h3{
    padding: 1rem;
  }
  .sublist-title {
    width: 100%;
    border-left: 0.3em solid #3c91e6;
    margin-bottom: 0.6em;
    padding: 0.5em 1em;
    display:grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 0.5fr 0.5fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    font-weight: bold;
    min-width: 925px;
    
  }
  
  .sublist-content {
    width: 100%;
    border-left: 0.3em solid #3c91e6;
    margin-bottom: 0.6em;
    justify-items: center;
    align-items: center;
    padding: 0.5em 1em;
    display:grid;
    grid-template-columns: 0.5fr 0.5fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    min-width: 925px;
  }
  
  .product {
    font-weight: 500;
    color: #363d55;
  }
  .amount {
    color: #414a67;
    margin-left: 20%;
  }
  .icons-container {
    width: 5em;
    margin: 1.2em;
    align-items: center;
  }
  .product-title {
    margin-bottom: 1em;
  }
  .hide {
    display: none;
  }
  .error {
    color: #ff465a;
  }
  
  .list .list-container .sublist-content .submit{
    margin: 0;
  }
  .list .list-container{
    overflow: auto;
  }
  @media screen and (max-width: 600px) {
    .wrapper {
      font-size: 14px;
    }
    .sub-container {
      grid-template-columns: 1fr;
      gap: 1em;
    }
  }
  .list .date-filter{
    padding: 0 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .list .date-filter input{
    display: inline-block;
    width: 10rem;
    padding: 0.6em 0.3em;
    border: 1px solid #d0d0d0;
    border-radius: 0.3em;
    color: #414a67;
    outline: none;
    font-weight: 400;
    margin: 0.6em;
  }