/* about.module.css */

:local(.about) {
    background-color: var(--secondary);
    padding: 5rem 7%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 1px;
  }

  .heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  :local(.container) {
    display: flex;
    flex-wrap: wrap;
  }
  
  :local(.about-image) {
    position: relative;
    flex: 1 1 40rem;
  }
  
  :local(.about-image img) {
    object-fit: cover;
    width: 80%;
    max-height: 350px;
    border-radius: 5%;
    box-shadow: var(--box-shadow);
  }
  
  :local(.about-image .about-img) {
    position: absolute;
    border-radius: 10%;
    border:2px solid white;
    bottom: -8rem;
    right: -0.5rem;
    width: 23rem;
    /* padding-block: 5rem; */
    margin-bottom: 5rem;
    max-height:250px;
  }

  :local(.about-content) {
    flex: 1 1 50rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 5rem;
  }
  
  :local(.about-content h3) {
    font-size: 3rem;
    color: var(--primary-color);
  }
  
  :local(.about-content p) {
    font-size: 1.6rem;
    font-weight: bold;
    color: #444;
    padding: 1rem 0;
    line-height: 1.8;
    text-align: justify;
  }

  @media (max-width:768px){

    .about .container .about-content{
      padding-left: 0;
    }
  
  }
  