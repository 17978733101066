.slick-dots {
    display: block;
    width: 100%;
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: -25px;
    margin-bottom: 5px !important;
    padding: 0;
}


.slick-dots li button {
font-size: 0;
line-height: 0;
display: block;
width: 15px !important;
height: 15px !important;
padding: 5px;
cursor: pointer;
border-radius: 50% !important;
outline: none;
background:gray !important;
}
.slick-dots li.slick-active button {
    background-color: #3C91E6 !important;
}