@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Forum&display=swap");

:root{
  --primary-color: #3C91E6;
  --secondary: #f8fcfe;
  --black: #141414;
  --white: #fff;
  --box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.517);
}

img{ 
  width: 20rem;
}

/* *{
  font-family: 'Times New Roman', Times, serif;
  margin: 0; padding: 0;
  box-sizing: border-box;
  outline: none; border: none;
  text-decoration: none;
  text-transform:capitalize;
  transition: all .2s linear;
  
} */


/* html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 9rem;  
  scroll-behavior: smooth;
} */
body {
  overflow-x: hidden;
  overflow-y: auto;
}

html::-webkit-scrollbar{
  width: 1rem;
}

html::-webkit-scrollbar-track{
  background: transparent;
}

html::-webkit-scrollbar-thumb{
  background: var(--primary-color);
}

/* section{
  padding: 5rem 7%;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 1px;
} */

.heading{
  position:relative;
  color: var(--primary-color);
  font-size: 3rem;
  text-decoration:underline 1px;
  text-underline-offset: 5px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .4rem;
  text-align: center;
  margin-bottom: 3rem;
}

.btn{
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  max-width: max-content;
  border: .1rem solid var(--primary-color);
  background: var(--secondary);
  padding: 1.2rem 4.5rem;
  overflow: hidden;
  z-index: 1;
  border-radius: .5rem;
  cursor: pointer;

  &:hover{
    background-color: var(--primary-color);
    color: white;
  }
}



.shine{
  position: relative;
}

input.form-control {
  font-size: 15px;
}

.shine::after{
  content: "";
  position: absolute;
  top: 0; left: 0; 
  width: 50%;
  height: 100%;
  background-image: linear-gradient(to right, transparent 0%, #fff6 100%);
  transform: skewX(-0.08turn) translateX(-180%);
}

.shine:is(:hover, :focus-within)::after{
  transform: skewX(-0.08turn) translateX(275%);
  transition: 1000ms ease;
}
a {
  text-decoration: none !important; /* Removes underline from all links */
}


@media (max-width:768px){

  #menu-btn{
    display: inline-block !important;
  }

  .header .navbar{
    position: absolute;
    top: 110%; right: -110%;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    background: var(--white);
  }

  .header .navbar.active{
    right: 2rem;
    transition: .4s linear;
  }

  .header .navbar a{
    font-size: 2rem;
    margin: 1rem 2.5rem;
    display: block;
  }

  

  .about .container .about-content{
    padding-left: 0;
  }

}

@media (max-width:450px){
  html{
    font-size: 50%;
  }
}
