/* MultiStepForm.css */
.step-form-container {
    /* max-width: 600px; */
    max-width: 100%;
    /* margin: 0 auto; */
    /* padding: 20px; */
    border: 0px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .step-form-container-head {
    max-width: 1000px;
    margin: 0 auto;
    /* padding: 20px; */
    border: 0px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .step-form-container h2 {
    text-align: center;
    /* margin-bottom: 20px; */
  }

.container {
  margin-top: 0 !important;
}

  .step-form-content {
    margin-bottom: 20px;
  }
  
  .step-form-navigation {
    text-align: center;
  }
  
  .step-form-navigation button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .step-form-navigation button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  