/* home.module.css */

.home {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: url(./images/Home.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    
  }
  
  .content {
    width: 100%;
    height: 100vh;
    padding: 6rem;
    padding-top: 15rem;
    /* background-color: rgba(128, 128, 128, 0.2); */
    background-color: rgba(255, 255, 255, 0.523);
    /* margin-top: 70px; */
  }
  @media screen and (max-width: 580px) {
    .content {
      max-width: 40rem;
      padding: 2rem 1rem;
    }
    .home{
      display: grid;
      place-items: center;
      padding: 0 !important;
    }

  }
  @media screen and (max-width: 440px) {
    .content {
      max-width: 35rem;
      padding: 2rem 0.5rem;
    }.home{
      display: grid;
      place-items: center;
      padding: 0 !important;
    }
  }
  
  .content h3 {
    font-size: 4rem;
    color: black;
    font-weight: bold;
    line-height: 1.2;
  }
  .heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  .content p {
    font-size: 2rem;
    line-height: 2;
    color: black;
    font-weight: bold;
    padding: 1rem 0;
  }

  .bttn{
    position: relative;
    display: inline-block;
    margin-top: 1rem;
    color: black;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    max-width: max-content;
    border: .1rem solid black;
    background: var(--secondary);
    padding: 1.2rem 4.5rem;
    overflow: hidden;
    z-index: 1;
    border-radius: .5rem;
    cursor: pointer;

    &:hover{
      background-color: black;
    color: var(--secondary);
    }
  }