.header{
  position:fixed;
    top: 0; left: 0; right: 0;
    z-index: 1200;
    height: 70px;
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 9%;
    overflow:visible;
    background-color: var(--secondary);
    transition: top 0.3s ease; 
    margin-left: 2px;
    margin-right: 2px;
  
}


.header .navbar a{
  font-size: 1.7rem;
  color: var(--black);
  margin: 0 1rem;
  padding-block: 1rem;
}

.header .navbar a:hover{
  color: var(--primary-color);
}

.header .navbar .hover-underline{
  position: relative;
  font-size: 20px;
  max-width: max-content;
}

.header .navbar .hover-underline::after{
  content: '';
  position: absolute;
  left: 0; bottom: 0;
  width: 100%;
  height: .5rem;
  border-block: .1rem solid var(--primary-color);
  transform: scaleX(0.2);
  opacity: 0;
  transition: 500ms ease;
}

.header .navbar .hover-underline:is(:hover, :focus-visible)::after{
  transform: scaleX(1);
  opacity: 1;
}

.header .icons div{
  font-size: 2.5rem;
  margin-left: 1.7rem;
  color: var(--black);
  cursor: pointer;
  display: inline;
}

.header .icons div:hover{
  color: var(--primary-color);
}

.header .icons .menubtn{
  display: none;
}

.header .login-form{
  position: absolute;
  top: 115%; right: -105%;
  background: var(--secondary);
  box-shadow: .5rem;
  width: 35rem;
  box-shadow: var(--box-shadow);
  padding: 2rem;
}

.header .login-form.active{
  right: 2rem;
  transition: .4s linear;
}

.header .login-form h2{
  font-size: 2.2rem;
  color: var(--black);
  margin-bottom: .7rem;
}

.close-form{
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  font-size: 3rem;
  cursor: pointer;
  color: gray;
  

  &:hover{
    transform: rotate(90deg);
    color: #141414;
  }
}

.header .login-form .box{
  font-size: 1.5rem;
  margin: .7rem 0;
  border: .1rem solid var(--primary-color);
  padding: 1rem 1.2rem;
  color: var(--black);
  text-transform: none;
  width: 100%;
}

/* .header .login-form .remember{
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: .7rem 0;
} */


.header .login-form .remember button{
  font-size: 1.5rem;
  color: var(--black);
  cursor: pointer;
}

.header .logo img{
  width: 20rem;
}

.header .login-form .bttn{
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  color: var(--primary-color);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  max-width: max-content;
  border: .1rem solid var(--primary-color);
  background: var(--secondary);
  padding: 1.2rem 4.5rem;
  overflow: hidden;
  z-index: 1;
  border-radius: .5rem;
  cursor: pointer;

  &:hover{
    background-color: var(--primary-color);
    color: white;
  }
}




@media (max-width:768px){

  .header .icons .menubtn{
    display: inline-block !important;
  }

  .header .navbar{
    position: absolute;
    top: 110%; right: -110%;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    background: var(--white);
  }

  .header .navbar.active{
    right: 2rem;
    transition: .4s linear;
  }

  .header .navbar a{
    font-size: 2rem;
    margin: 1rem 2.5rem;
    display: block;
  }
}