
@media screen and (max-width: 540px) {
   .navbar-title{
    font-size: 20px !important;
   }
  }
  
  
@media screen and (max-width: 770px) {
  .sidebar-icon{
    height: 10px !important;
  }
 }
 
 *{
  font-family: 'Cambria','Times New Roman',Times, serif !important;
  font-size:large;
 }
 
  
 