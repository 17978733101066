.blog {
    background-color: var(--secondary);
  }
  .heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  .box-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
  }
  
  .box {
    box-shadow: var(--box-shadow);
    border-radius: 2rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 40rem;
  }
  
  .image {
    height: 100%;
    width: 40rem;
    overflow: hidden;
    position: relative;
    border-radius: 2rem;
  }
  :local(.section ){
    padding: 5rem 7%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 1px;
  }
  
  .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .box:hover .image img {
    transform: scale(1.1);
    
  }
  .box:hover .content{
    bottom: 0;
    transition: all 0.2s linear !important;

  }
  .image h3 {
    font-size: 1.5rem;
    color: #444;
    position: absolute;
    bottom: 1.5rem;
    left: 1rem;
    padding: .5rem 1.5rem;
    background: var(--white);
    border-radius: 2rem 0 2rem 0;
  }
  
  .content {
    padding: 2rem;
    background-color: #3c91e6d6;
    
    border-radius: 2rem;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    bottom: -40rem;
    left: 0;
    text-align: center;
  }
  .content h1 {
    color: white;
    font-size: 3rem;
  }
  
  .content h2 {
    color: white;
    font-size: 2rem;
    line-height: 2;
    padding: 1rem 0;
  }
  .content h3{
    position: absolute;
    bottom: 0;
    left: 2rem;
    color: white;
  }
  
  
  
  @media screen and (max-width: 800px) {
    .box-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  
  @media screen and (min-width: 801px) and (max-width: 1500px) {
    .box-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }
  
  