/* contact.module.css */

:local(.contact) {
    background-color: var(--secondary);
  }
  :local(.section ){
    padding: 5rem 7%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 1px;
  }
  :local(.contact .row) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background-color: var(--white);
  }
  
  :local(.contact .row .image) {
    flex: 1 1 40rem;
  }
  
  :local(.contact .row .image img) {
    width: 100%;
  }
  
  :local(.contact .row form) {
    flex: 1 1 40rem;
    border: 0.1rem solid var(--primary-color);
    padding: 2rem;
  }
  
  :local(.contact .row form h3) {
    font-size: 2.5rem;
    color: #444;
    padding-bottom: 1rem;
  }
  
  :local(.contact .row form .box) {
    width: 100%;
    font-size: 1.6rem;
    padding: 1.2rem 1.4rem;
    border: 0.1rem solid var(--primary-color);
    margin: 0.7rem 0;
    text-transform: none !important;
  }
  .heading{
    position:relative;
    color: #3C91E6;
    font-size: 3rem;
    text-decoration:underline 1px;
    text-underline-offset: 5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  :local(.contact .row form .box:focus) {
    color: var(--primary-color);
  }
  
  :local(.contact .row form textarea) {
    height: 15rem;
    resize: none;
  }
  
  :local(.contact .row form .popup) {
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 1.6rem;
    color: var(--primary-color);
  }

  .bttn{
    position: relative;
    display: inline-block;
    margin-top: 1rem;
    color: var(--primary-color);
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    max-width: max-content;
    border: .1rem solid var(--primary-color);
    background: var(--secondary);
    padding: 1.2rem 4.5rem;
    overflow: hidden;
    z-index: 1;
    border-radius: .5rem;
    cursor: pointer;
  
    &:hover{
      background-color: var(--primary-color);
      color: white;
    }
  }
  